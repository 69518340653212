import React from 'react';
import { useState, useEffect } from 'react';


const supportedCrops = ["potato", "sugarbeet", "maiz", "wheat", "barley", "vine", "grape", "straw", "tomato", "brinjal", "eggplant", "rice", "corn"];

const MessageParser = ({ children, actions }) => {

  const [limit, setLimit] = useState(0);
  const [currentDate, setCurrentDate] = useState(Date.now());
  useEffect(() => {
    setCurrentDate(Date.now())
    window.localStorage.setItem('DAILY_USAGE_LIMIT', currentDate);
  }, [limit]);
  const parse = (message) => {

    if (limit <= 5) {
      setLimit(limit + 1);
      if (supportedCrops.some(item => message.toLowerCase().includes(item))) {
        actions.handleHello(message, true);
      }
      else {
        actions.handleHello(message, false);
      }
    }
    else if (limit > 5) {
      const data = window.localStorage.getItem('DAILY_USAGE_LIMIT');
      var dateNow = Date.now();
      var diff = dateNow - data;
      diff = Math.floor(diff / 1000);
      var secs_diff = diff % 60;
      diff = Math.floor(diff / 60);
      var mins_diff = diff % 60;
      if (mins_diff > 120) {
        setLimit(0)
      }

      actions.handleHello("unlock", false)
    }

    else
      actions.handleHello(message, false);
  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          parse: parse,
          actions: {},
        });
      })}
    </div>
  );
};

export default MessageParser;