import React from 'react'
import Navbar from '../components/Navbar'


const Imprint = () => {
    return (
        <div>
            <Navbar />
            <br />
            <p>Imprint</p>
            <p>Information according to § 5 TMG</p>
            <p>Raise Agriculture<br></br>Landshuter Starasse 21<br></br>85354 Freising</p>
            <p>Represented by: Sebastian Michel</p>
            <p>Contact:</p>
            <p>E-mail: abir@raiseagri.com</p>
            <p>Responsible for the content according to § 55 Abs. 2 RStV:</p>
            <p>Sebastian Michel<br />Landshuter Straße 21<br />85356 Freising</p>
            <p>Disclaimer:</p>
            <p>Disclaimer:</p>
            <p>The contents of our pages have been created with the utmost care.
                For<br />the accuracy, completeness and timeliness of the contents, we can<br />assume any liability for the correctness,
                completeness and As a service provider we are responsible according to § 7
                <br />1 TMG (Telemedia Act) for own contents on these pages according to the general<br />
                responsible for our own content on these pages. According to §§ 8 to 10 TMG, we are as a service<br />
                service provider, we are not obligated to monitor transmitted or stored<br />
                to monitor transmitted or stored third-party information or to investigate circumstances<br />
                or to investigate circumstances that indicate illegal activity.<br />Obligations to remove or block the use of
                <br />information according to the general laws remain unaffected.<br />
                However, any liability in this respect is only possible from the time of<br />
                knowledge of a concrete violation of the law. Upon becoming aware of<br />
                violations of the law, we will remove this content immediately.<br />removed.</p>
            <p>Liability for links</p>
            <p>Our offer contains links to external websites of third parties, on whose
                contents we have no influence.<br />
                on whose contents we have no influence. Therefore, we can for these foreign<br />
                for these foreign contents. For the contents of the linked<br />
                the respective provider or operator of the pages is always responsible<br />responsible.
                The linked pages were checked for possible legal<br />checked for possible legal violations
                at the time of linking. Illegal contents<br />were not recognizable at the time of linking.
                A permanent<br />control of the contents of the linked pages is not reasonable without<br />
                without concrete evidence of a violation of the law is not reasonable. If we become aware of<br />
                violations of the law, we will remove such links immediately.</p>
            <p>Copyright</p>
            <p>The contents and works on these pages created by the site operators are subject to<br />
                pages are subject to German copyright law. The duplication,<br />distribution and any kind of
                exploitation outside the limits of copyright law<br />copyright law requires the written consent of
                the author or creator.<br />author or creator. Downloads and copies of this site<br />are only permitted for private,
                non-commercial use.<br />As far as the contents on this page were not created by the operator,<br />the copyrights of third parties are respected. In particular, contents of<br /
                >third parties are marked as such. Should you nevertheless become aware of a<br />
                copyright infringement, we ask you to inform us accordingly.<br />appropriate notice.
                If we become aware of any infringements, we will remove the relevant<br />we will remove such content immediately.</p>

            <p>Data protection</p>

            <p>The use of our website is generally possible without providing any<br />personal data.
                As far as on our sides<br />personal data (for example name, address or e-mail addresses)<br />e-mail addresses)
                is collected, this is done, as far as possible, on a voluntary basis.<br />voluntary basis.
                This data will not be passed on to third parties without your<br />to third parties without your express consent
                . Detailed<br />privacy policy can be found here<br />We point out that the<br />data transmission over the Internet
                (eg communication by e-mail)<br />can have security gaps. A complete protection of data against<br />access by
                third parties is not possible.<br />The use of<br />published contact data by third parties for the purpose
                of sending unsolicited<br />for the purpose of sending unsolicited advertisements and
                information material<br />information material is hereby expressly prohibited. The<br />
                operators of the pages expressly reserve the right to take legal<br />the case of unsolicited sending of
                advertising information, for example through spam mails.<br />spam mails, for example.</p>
            <p>Google Analytics</p>

            <p>This website uses Google Analytics, a web analytics service provided by Google, Inc. (”Google”).
                Google Analytics uses so-called ”cookies”, which are text files placed on your computer,
                to help the website analyze how users use the site. The information generated by the cookie about your use
                of the website (including your IP address) will be transmitted to and stored by Google on servers in
                the United States. Google will use this information for the purpose of evaluating your use of the website,
                compiling reports on website activity for website operators and providing other services relating to website
                activity and internet usage. Google may also transfer this information to third parties where required to do
                so by law, or where such third parties process the information on Google’s behalf.
                Google will not associate your IP address with any other data held by Google. You may refuse the use of
                cookies by selecting the appropriate settings on your browser, however please note that if you do this you
                may not be able to use the full functionality of this website. By using this website, you consent to
                the processing of data about you by Google in the manner and for the purposes set out above.</p>
            <p>Imprint from the imprint generator of the Hasselbach law firm, Frankfurt</p>
            <p>&nbsp;</p>

        </div>)
}

export default Imprint;