import config from '../components/chatbot/config';
import logic from '../components/chatbot/logic';
import MessageParser from '../components/chatbot/MessageParser';
import ActionProvider from '../components/chatbot/ActionProvider';
import Chatbot from 'react-chatbot-kit'
import 'react-chatbot-kit/build/main.css'
import './chat.css'
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import "./home.css"
import IconButton from '@mui/material/IconButton';

const Chat = ({ isOpen, handleClick }) => {
  return (
    <div className={`floating-chat ${isOpen ? 'open' : ''}`}>
      {isOpen && (
        <Chatbot
          config={config}
          messageParser={MessageParser}
          actionProvider={ActionProvider}
          logic={logic}
          opened={isOpen}
          onSendMessage={() => { }}
        />
      )}
      <div className="floating-chat-button">
        <IconButton aria-label="chat" onClick={handleClick} color="success">
          <HeadsetMicIcon />
          {isOpen ? 'Hide' : 'Chat'}
        </IconButton>

      </div>
    </div>
  );
};

export default Chat