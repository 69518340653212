import React from 'react'
import PricingCards from '../components/Pricing'
import HeroImagePricing from '../components/HeroImagePricing'

const Pricing = () => {
    const [width, setWidth] = React.useState(window.innerWidth);
    React.useEffect(() => {
        const handleResizeWindow = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleResizeWindow);
        return () => {
            window.removeEventListener("resize", handleResizeWindow);
        };
    }, []);
    return (
        <div>
            {width > 1000 && <HeroImagePricing heading='PRICING.' text='Choose your trip.' />}
            <PricingCards />
        </div>
    )
}

export default Pricing
