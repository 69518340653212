import Flippy, { FrontSide, BackSide } from 'react-flippy';
import abir from '../assets/Team/abir.jpg';
import seb from '../assets/Team/seb.jpg';
import nilav from '../assets/Team/Nilav.jpg';
import pauline from '../assets/Team/pauline.png';
import jovelyn from '../assets/Team/jovelyn.jpg';
import hien from '../assets/Team/hien.jpg';
import taha from '../assets/Team/taha.jpg';
import alberto from '../assets/Team/Alberto.png';
import elena from '../assets/Team/Elena.jpeg';
import { FaLinkedin } from 'react-icons/fa'

const TeamCard = (props) => {
  var placeHolder = null;
  if (props.name.includes("Abir")) {
    placeHolder = abir;
  }
  if (props.name.includes("Seb")) {
    placeHolder = seb;
  }
  if (props.name.includes("Nilav")) {
    placeHolder = nilav;
  }
  if (props.name.includes("Pauline")) {
    placeHolder = pauline;
  }
  if (props.name.includes("Jovelyn")) {
    placeHolder = jovelyn;
  }
  if (props.name.includes("Hien")) {
    placeHolder = hien;
  }
  if (props.name.includes("Taha")) {
    placeHolder = taha;
  }
  if (props.name.includes("Alberto")) {
    placeHolder = alberto;
  }
  if (props.name.includes("Elena")) {
    placeHolder = elena;
  }

  const openInNewTab = (url) => {
    window.open(url, "_blank");
  }
  return (
    <Flippy
      flipOnHover={true}
      flipDirection="horizontal"
      style={{ height: "480px", width: "480px" }}
    >
      <FrontSide style={{
        backgroundImage: `url(${placeHolder})`, backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }} >
        <div style={{ marginTop: "410px", alignContent: 'center' }}>
          <h1 style={{ textAlign: "center" }}>{props.name}</h1>
        </div>
      </FrontSide>
      <BackSide>
        <div style={{ alignContent: 'center' }}>
          <h1 style={{ textAlign: "center" }}>{props.title}</h1>
        </div>
        <br></br>
        <p>{props.desc} </p>
        <br></br>
        <div className='social' style={{ marginTop: "210px", alignContent: 'center' }}>
          <FaLinkedin onClick={() => openInNewTab(props.linkedIn)} size={30} style={{ color: '#ffffff', marginRight: '1rem' }} />
        </div>
      </BackSide>
    </Flippy>
  )
}

export default TeamCard