import { createChatBotMessage } from 'react-chatbot-kit';


const config = {
  botName: 'Rinno',
  userAvatar: '../../assets/client.svg',
  customStyles: {
    botMessageBox: {
      backgroundColor: "#025D4C",
    },

    userAvatarBackgroundColor: 'grey'
  },
  initialMessages: [
    createChatBotMessage('Hello! How can I assist you today?'),
    createChatBotMessage('Try phrases like My potato leaves are yellow...'),
  ],
};


export default config;