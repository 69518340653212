import React from "react";
import Carousel from "react-elastic-carousel";
import TeamCard from "./TeamCard";
import "./teamCarousel.css";

const TeamCarousel = () => {
  const items = [
    {
      id: 1,
      name: "Abir Bhattacharyya",
      description:
        "Abir, the technical genius behind Raise Agriculture, spins complex webs of code with artistry (pun intended!). His AI expertise and passion for innovation drive him to conquer intricate challenges and leave a lasting, meaningful impact. As an inventor, he cultivates a future where agriculture blossoms with unlimited possibilities",
      title: "The Web Slinger",
      linkedIn: "https://www.linkedin.com/in/abir-bhattacharyya/"
    },

    {
      id: 2,
      name: "Sebastian Michel",
      description: "Meet Sebastian, the unstoppable sharpshooter, obliterating obstacles for the company's triumph! His intuition fuels an agile team, harnessing the latest market developments with precision. As the dynamic bridge between product and customer, he flawlessly deciphers their needs, morphing them into tangible product characteristics. A relentless force of innovation, Sebastian empowers a future where success knows no bounds!",
      title: "The Sniper",
    },
    {
      id: 4,
      name: "Nilav Sharma",
      description:
        "Nilav, the daring adventurer, fearlessly embraces diverse environments, leading projects abroad with fervor! A trailblazer in market discovery, he collaborates with stakeholders, guaranteeing top-notch product delivery. With boundless energy and expertise, Nilav ignites the team's passion, propelling them to conquer every challenge that comes their way! ",
      title: "The Charger",
      linekdIn: "https://www.linkedin.com/in/nilav-sharma-a80621239/"
    },
    {
      id: 5,
      name: "Jovelyn Dato",
      description:
        "Meet our numerical wizard! Armed with accounting and financing prowess, she casts her enchanting gaze on the company's growth, ensuring harmony like a thriving potato plant. Like a guardian of precision, she ensures the numbers align flawlessly, leaving no room for error. Trust her to weave financial magic, guiding the company's journey with unwavering assurance!",
      title: "The Analyst",
      linekdIn: "https://www.linkedin.com/in/jovelynd/"
    },
    {
      id: 6,
      name: "Hien Nyugen",
      description:
        "Meet the trailblazer in remote sensing, revolutionizing web application integration! A dynamic force collaborating with the web slinger, he's ushering farmers into a realm of cutting-edge technology. With expertise that ignites possibilities, he orchestrates the perfect symphony of innovation, ensuring a seamless blend of remote sensing and web wizardry. Together, they unleash a new era of empowerment for farmers across the globe!",
      title: "The Observer",
      linkedIn: "https://www.linkedin.com/in/hien-n-20ab20a5/"
    },
    {
      id: 7,
      name: "Taha Altikriti",
      title: "The Designer",
      description:
        "Behold the visionary genius! From crafting robots to mesmerizing mechanical gears, he sets innovation ablaze! Fueling the team's success, he enables seamless data acquisition, empowering algorithms to transform farming. With boundless creativity and expertise, he paints a futuristic landscape where technology nurtures the fields, ushering farmers into a bountiful tomorrow!",
      linkedIn: "https://www.linkedin.com/in/altikrititaha/"
    },

    {
      id: 8,
      name: "Alberto Moreno",
      description:
        "Meet Alberto, the maestro of biology and biodiversity! With unrivaled expertise, he curates captivating content, wielding facts like a virtuoso for marketing and networking. Fueled by a passion to preserve Earth's treasures, he dauntlessly delves into research, safeguarding biodiversity hotspots. A visionary guardian, Alberto envisions a world where knowledge blooms, connecting hearts and minds in a symphony of conservation!",
      title: "The Informant",
      linkedIn: "https://www.linkedin.com/in/alberto-moreno-miranda-ab2245229/"
    },

    {
      id: 9,
      name: "Dr. Elena Vazquez",
      description:
        "Meet Elena, the agricultural vanguard! Armed with a team of experts, she passionately crafts a revolutionary product based on farmers' feedback. Fearless in her pursuit of perfection, she ignites product market fit in Germany and beyond. As a proud farm owner, Elena embodies her mission, bridging the gap between innovation and cultivation, cultivating a greener, prosperous tomorrow!",
      title: "The Agronomist",
      linkedIn: "https://www.linkedin.com/in/maria-elena-vazquez-novoa-b0140673/"
    },
  ];
  const carouselRef = React.useRef(null);
  let resetTimeout;
  return (
    <div className="team-carousel">
      <div style={{ textAlign: "center" }}>
        <h1> MEET THE RAISERS</h1>
      </div>
      <br></br>
      <Carousel
        itemsToShow={1}
        enableAutoPlay={true}
        autoPlaySpeed={10000}
        ref={carouselRef}
        onNextEnd={({ index }) => {
          if (index === items.length - 1) {
            clearTimeout(resetTimeout);
            resetTimeout = setTimeout(() => {
              carouselRef?.current?.goTo(0);
            }, 10000);
          }
        }}
      >
        {items.map((item) => (
          <TeamCard
            key={item.id}
            name={item.name}
            desc={item.description}
            title={item.title}
            linkedIn={item.linkedIn}
          />
        ))}
      </Carousel>
      <p style={{ textAlign: "center" }}>*Yes, we need better pictures we agree</p>
    </div>
  );
};

export default TeamCarousel;
