import React from "react";
import "./FooterStyles.css";
import {
  FaLinkedin,
  FaMailBulk,
  FaPhone,
  FaSearchLocation,
} from "react-icons/fa";
import { Link } from 'react-router-dom'

const Footer = () => {

  const openInNewTab = (url) => {
    window.open(url, "_blank");
  }
  return (
    <div className="footer">
      <div className="footer-container">
        <div className="left">
          <div className="location">
            <FaSearchLocation
              size={20}
              style={{ color: "#ffffff", marginRight: "2rem" }}
            />
            <div>
              <p>Landshuter straße 21</p>
              <h4>Freising Germany</h4>
            </div>
          </div>
          <div className="phone">
            <h4>
              <FaPhone
                size={20}
                style={{ color: "#ffffff", marginRight: "2rem" }}
              />{" "}
              +49 15236700136
            </h4>
          </div>
          <div className="email">
            <h4>
              <FaMailBulk
                size={20}
                style={{ color: "#ffffff", marginRight: "2rem" }}
              />{" "}
              info@raiseagri.com
            </h4>
          </div>
        </div>
        <div className="right">
          <h4>About the company</h4>€
          <p>
            "Welcome to Raise Agriculture, where we're on a thrilling mission to revolutionize farming for medium to large scale farmers! Picture this: a futuristic world of agriculture where cutting-edge research meets the soil beneath our feet.
            Imagine being a farmer with access to an autonomous resource management system, guiding you through the vast fields of uncertainty in the market. The days of worrying about fluctuating yields are over! Our vision is to empower you, the farmers, with the tools and technology to maintain or even exceed your current yields.As the sun rises on this new era, we're bringing the latest advancements right to your doorstep. From AI-driven farming techniques to innovative crop monitoring systems, Raise Agriculture is your ally in cultivating success. Together, we'll sow the seeds of innovation and reap the harvest of a sustainable future."
          </p>
          <p>
            <Link to="/about" className="about-button"> Know More </Link>
          </p>
          <div className="social">
            <FaLinkedin
              size={30}
              style={{ color: "#ffffff", marginRight: "1rem" }}
              onClick={() => openInNewTab("https://www.linkedin.com/company/raise-agriculture/")}
            />
            
          </div>
          <Link to="/imprint">Imprint</Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;
