import React from "react";
import Carousel from 'react-elastic-carousel';
import Cop from '../assets/Awards/Cop.png';
import esf from '../assets/Awards/esf.png';
import Exist from '../assets/Awards/Exist.png';
import fd from '../assets/Awards/fd.png';
import innova from '../assets/Awards/innova.png';
import micro from '../assets/Awards/micro.png';
import Netlite from '../assets/Awards/Netlight.png';
import tum from '../assets/Awards/tum.png';
import ut from '../assets/Awards/Ut.png';
import vl from '../assets/Awards/vl.png';
import xplore from '../assets/Awards/xplore.png';

const AwardsCarousel = () => {
  const carouselRef = React.useRef(null);
  const items = [
    Cop, esf, Exist, fd, innova, micro, Netlite, tum, ut, vl, xplore
  ]
  let resetTimeout;
  return (
    <div style={{ background: 'white' }}>
      <div style={{ textAlign: "center" }}>
        <h1> Awards, Partners and Sponsors</h1>
      </div>
      <br></br>
      <Carousel
        itemsToShow={1}
        enableAutoPlay={true}
        autoPlaySpeed={1000}
        ref={carouselRef}
        onNextEnd={({ index }) => {
          if (index === items.length - 1) {
            clearTimeout(resetTimeout);
            resetTimeout = setTimeout(() => {
              carouselRef?.current?.goTo(1);
            }, 1000);
          }
        }}
      >
        {items.map((item) => (
          <div style={{ width: "auto", height: "auto", alignContent: "center" }} key={item}><img src={item} alt=''></img></div>
        ))}
      </Carousel>
    </div>
  )
}

export default AwardsCarousel;