import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import Award from './Awards'
import Pricing from './Pricing'
import Team from './Team'
import Training from './Training'
import fallback from '../assets/fallback.jpg'
import Chat from './Chat'
import "./home.css"
import { useState } from 'react';

const Video = React.lazy(() => import("../components/Video"))


const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleFloatingChatClick = () => {
    setIsOpen((prev) => !prev);
  };
  return (
    <div>
      <Navbar />
      <React.Suspense fallback={<div> <img alt="farm" src={fallback} />
      </div>}>
        <Video />
      </React.Suspense>
      <Training />
      <Pricing />
      <Team />
      <Award />
      <div className="floating-chat-container">
        <Chat isOpen={isOpen} handleClick={handleFloatingChatClick}></Chat>
      </div>
      <Footer />
    </div>
  )
}

export default Home
