import AwardsCarousel from "../components/AwardsCarousel"

const Award = () => {
    return (
        <div>
            <AwardsCarousel></AwardsCarousel>
        </div>
    )
}

export default Award