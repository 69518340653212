import React from 'react'
import { Link } from 'react-router-dom'
import './PricingStyles.css'

const Pricing = () => {
    return (
        <div className='pricing'>
            <div style={{ textAlign: "center" }}>
                <h1>PRICING</h1>
            </div>
            <br></br>
            <div>
                <div className='card-container'>
                    <div className='card'>
                        <h3>- Basic -</h3>
                        <h5>- Get started today. Upload images from your farm at your convinience -</h5>
                        <span className='bar'></span>
                        <p className='btc'>25 € per  hectare</p>
                        <p>- 5 Hectares survey via Satellite included-</p>
                        <p>- Monthly twice dicounted with more hectares and frequency -</p>
                        <p>- 24/7 support and assistance from the team -</p>
                        <p>- Newsletter, Offers and more -</p>
                        <Link to='/contact' className='btn'>Book</Link>
                    </div>
                    <div className='card' style={{ backgroundColor: "black", boxShadow: "10px 5px 5px white" }}>
                        <h3>- Suite -</h3>
                        <h5>- Most popular: Get a data acquisition tool with your subscription -</h5>
                        <span className='bar'></span>
                        <p className='btc'>60 € per hectare</p>
                        <p>- 25 Hectares survey via Satellite included -</p>
                        <p>- Limitless upgrade to the software and tool -</p>
                        <p>- Resource Management Models fully customized -</p>
                        <p>- Newsletter, Offers and more -</p>

                        <Link to='/contact' className='btn'>Book</Link>
                    </div>
                    <div className='card'>
                        <h3>- Executive -</h3>
                        <h5>- Want to just manage your field and not worry about acquiring your own data -</h5>
                        <span className='bar'></span>
                        <p className='btc'>Ask for Quote</p>
                        <p>- We will come up with a optimized plan to maximize your crop management decisions with all the things you
                            want to be detected -</p>
                        <p></p>
                        <p></p>
                        <p></p>

                        <Link to='/contact' className='btn'>Book</Link>
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "center", textAlign: "center" }}>
                    <p>*Get started as little as 5 euros today, </p>
                    <br></br>
                    <p> use our Chat-Gpt powered virtual assistant for planning your crop in your language</p>
                </div>
            </div>
        </div>
    )
}

export default Pricing
