import React from 'react'
import './TrainingStyles.css'
import { Link } from 'react-router-dom'
import Pod from '../assets/soya.jpg'
import Moon from '../assets/sat.gif'

const Training = () => {
    return (
        <div className='training' style={{ background: "#048433" }}>
            <div className='left' >
                <h1 >Eyes From the Space and Sky</h1>
                <p >Through our unique integration of satellite data and deep learning algorithms get details such as
                    growth, infestations and damages due to sudden climate change during any time in the season.</p>
                <Link to='/contact'><button className='btn' style={{ color: 'white', fontWeight: 'bold', boxShadow: '10px 5px 5px gray' }}>Contact For More Details</button></Link>
            </div>
            <div className='right'>
                <div className='img-container'>
                    <div className='image-stack top'>
                        <img src={Moon} className='img' alt='' />
                    </div>
                    <div className='image-stack bottom'>
                        <img src={Pod} className='img' alt='' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Training
