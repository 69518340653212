import React, { Component } from 'react'
import './HeroImagePricingStyles.css'

class HeroImagePricing extends Component {
    render() {
        return (

            <div className='hero-img-pricing'>
                <div className='heading-pricing'>
                    <h1>{this.props.heading}</h1>
                    <p>{this.props.text}</p>
                </div>

            </div>

        )
    }
}

export default HeroImagePricing
