import React from "react";

const apiKey = `${process.env.REACT_APP_API_KEY}`;
const apiUrl = `${process.env.REACT_APP_AUTH_DOMAIN}`;

const ActionProvider = ({ createChatBotMessage, setState, children }) => {
  const handleChat = async (message) => {
    let generatedText;
    var response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${apiKey}`,
      },
      body: JSON.stringify({
        model: "gpt-3.5-turbo",
        messages: [{ role: "user", content: "summarize why/how " + message }],
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        generatedText = data.choices[0].message.content;
      })
      .catch((error) => console.error(error));
    return generatedText;
  };

  const handleHello = async (message, chat) => {
    let reply =
      "Oops! We do not have knowledge of that. Please use the contact form to connect with our senior assistant.";
    if (message.includes("unlock"))
      reply = "To further use the powerful chat fascility. Please use the contact form to connect with our senior assistant. "
    if (chat) reply = await handleChat(message);
    const botMessage = createChatBotMessage(reply);
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };
  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            handleHello,
          },
        });
      })}
    </div>
  );
};

export default ActionProvider;
