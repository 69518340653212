const logic = [
  {
    name: 'greet',
    trigger: 'hello',
    user: true,
    steps: [
      {
        id: 'hello',
        message: 'Hi there! How can I help you today?',
        trigger: 'end',
      },
      {
        id: 'end',
        message: 'Goodbye!',
        end: true,
      },
    ],
  },
];


export default logic;