import React from 'react'
import './FormStyles.css'
import emailjs from 'emailjs-com'

const Form = () => {
  const SERVICE_ID = "raise_contact";
  const TEMPLATE_ID = "template_l3v4gsa";
  const USER_ID = "dTQj7pSJuvS8bcOLd";

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, USER_ID)
      .then((result) => {

      }, (error) => {
        console.log(error.text);
      });
    e.target.reset()
  };

  return (
    <div className='form'>
      <form onSubmit={handleSubmit}>
        <label>Your Name*</label>
        <input type='text' name='name' id='name'></input>
        <label>Email*</label>
        <input type='email' name='email' id='email' ></input>
        <label>Subject</label>
        <input type='text' name='subject' id='subject'></input>
        <label>Details</label>
        <textarea rows='6' placeholder='Type a short message here' name="message" id="message" />
        <button className='btn' type="submit">Submit</button>
      </form>
    </div>
  )
}

export default Form
