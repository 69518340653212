import React from 'react'
import TeamCarousel from '../components/TeamCarousel'


const Team = () => {
    return (
        <div>
            <TeamCarousel></TeamCarousel>
        </div>
    )
}

export default Team