import React from 'react'
import HeroImage from '../components/HeroImage'
import TrainingSection from '../components/Training'

const Training = () => {
    const [width, setWidth] = React.useState(window.innerWidth);
    React.useEffect(() => {
        const handleResizeWindow = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleResizeWindow);
        return () => {
            window.removeEventListener("resize", handleResizeWindow);
        };
    }, []);
    return (
        <div>
            {width > 1000 && <HeroImage image='../assets/heroImg.png' />}
            <TrainingSection />
        </div>
    )
}

export default Training
