import Navbar from "../components/Navbar";
import imageSrc from "../assets/shoennege.jpg"
import videoSrc from "../assets/ThistleDetect.mp4"
import "./about.css"


const About = () => {
  return (

    <div className="about-page">
      <Navbar />
      <div className="about-container">
        <h1 className="about-heading">About Us</h1>
        <div className="about-content">
          <div className="text-content">
            <h2>Our Mission</h2>
            <br />
            <p>
              At RAISE, we are on a mission to equip small to medium-scale farmers with affordable precision agriculture tools,
              enabling them to tackle climate change, market uncertainties,
              and escalating labor and raw material costs.
            </p>
            <br />
            <p>
              We understand that farming is a way of life,
              and our goal is to improve the quality of life for farmers by reducing physical and mental burdens.
              Through sustainable practices, we promote resource conservation,
              while data-driven farm management optimizes resource utilization.
            </p>
            <br />
            <p>
              By providing cost-effective solutions,
              we aim to lower barriers to entry and empower farmers with climate-resilient tools, data-driven insights,
              and decision support systems. Our technology enables informed choices in crop planning, resource management,
              and market-driven production strategies, enhancing efficiency and profitability.
            </p>
          </div>
          <div className="image-caption-container">
            <img src={imageSrc} alt="About Us" className="about-image" />
            <p className="image-caption">Our team members explaining the</p>
            <p className="image-caption">  benefits of affordable drone equiped cameras</p>
          </div>
        </div>
      </div>
      <div className="vision-container">

        <div className="vision-content">
          <div className="vision-video">
            <video autoPlay loop muted  >
              <source src={videoSrc} type="video/mp4" />
            </video>
            <p className="video-caption">Real Time Thistle Detection using mobile phones to protect sheep grazing </p>
          </div>
          <div className="vision-text-content">
            <h2>Our Vision</h2>
            <br />
            <p>
              At RAISE, our vision is to revolutionize agriculture with an autonomous, real-time plant health monitoring and resource management system that is affordable, accessible, and easy to understand.
              We aim to democratize precision agriculture, making advanced technology available to farmers of all sizes and backgrounds.
            </p>
            <br></br>
            <p>
              Our key principles include affordability, ensuring that even small-scale farmers
              can benefit from our cutting-edge system. We prioritize accessibility
              through user-friendly interfaces and comprehensive support, making our technology usable worldwide.
            </p>
            <br></br>
            <p>
              We focus on providing easy-to-understand insights that empower farmers to optimize their practices effectively.
              Real-time monitoring enhances crop resilience and productivity,
              while our autonomous approach streamlines resource management sustainably, reducing farmers' workload
            </p>
          </div>
        </div>
        <div>

        </div>
      </div>
    </div>
  );

}

export default About